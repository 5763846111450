<template>
  <div>
    <div>
      <breadcrumb :breadcrumb_link="eventinquiry_breadcrumb" />
    </div>
    <div>
      <Eventinquiry v-show="stage === 0" @nextPage="nextPage" />
    </div>
    <div>
      <Eventcontact v-show="stage === 1" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Eventinquiry from '@/pages/Footerlink/Eventinquiry';
import Eventcontact from '@/pages/Footerlink/Eventcontact';
export default {
  name: 'EventMain',
  components: {Breadcrumb, Eventinquiry, Eventcontact},
  data() {
    return {
      eventinquiry_breadcrumb: {
        toplink: 'HOME',
        prevlink: '행사문의',
        present: '행사문의',
      },
      stage: 0,
    };
  },
  methods: {
    nextPage() {
      console.log('NEXT PAGE CALL');
      this.stage = 1;
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '행사문의'});
  },
};
</script>

<style scoped></style>
